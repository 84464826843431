import Seo from 'src/components/seo/Seo'
import CupomDesconto from 'src/components/servicos/ServicesPage/CupomDesconto'

function Page() {
  return (
    <>
      <Seo title="Cupons de Desconto | Decathlon" />
      <CupomDesconto />
    </>
  )
}

export default Page
